section {
    min-height: 100vh;
    display: flex;
    align-items: center;
}

.login-form {
    display: flex;
    align-items: center;
    
    padding: 40px;
    display: inline-block;
    width: 100%;
    min-width: 540px;
    
    text-align: left;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.login-form form {
    border: 3px solid #fff;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    min-width: 540px;
}

.login-form h2 {
    color: #fff;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 5px;
}

.login-form h3.form-title {
    margin-bottom: 30px;
}
    
.login-form p {
    color: #fff;
    text-align: left;
    font-size: 17px;
    font-weight: 300;
    line-height: 20px;
    margin-bottom: 30px;
}
    
    
.login-form label, .was-validated .form-check-input:invalid~.form-check-label, .was-validated .form-check-input:valid~.form-check-label{
    color: #fff;
}
    
.login-form input[type=number], .form-content input[type=password], .form-content input[type=text], .form-content select {
    width: 100%;
    padding: 9px 20px;
    text-align: left;
    border: 0;
    outline: 0;
    border-radius: 6px;
    background-color: #fff;
    font-size: 15px;
    font-weight: 300;
    color: #8D8D8D;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    margin-top: 16px;
}

.login-form btn {
    text-align: center;
}

.modal-body {
    text-align: center;
    font-size: 28px;
    color: #ee7742;
}