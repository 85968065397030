.App {
  text-align: center;
}

.App-logo {
  height: 60vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.form-holder .form-content {
position: relative;
text-align: center;
display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex;
display: flex;
-webkit-justify-content: center;
justify-content: center;
-webkit-align-items: center;
align-items: center;
padding: 60px;
}

.form-content .form-items {
border: 3px solid #fff;
padding: 40px;
display: inline-block;
width: 100%;
min-width: 540px;
-webkit-border-radius: 10px;
-moz-border-radius: 10px;
border-radius: 10px;
text-align: left;
-webkit-transition: all 0.4s ease;
transition: all 0.4s ease;
}

.form-content h3 {
color: #fff;
text-align: left;
font-size: 28px;
font-weight: 600;
margin-bottom: 5px;
}

.form-content h3.form-title {
margin-bottom: 30px;
}

.form-content p {
color: #fff;
text-align: left;
font-size: 17px;
font-weight: 300;
line-height: 20px;
margin-bottom: 30px;
}


.form-content label, .was-validated .form-check-input:invalid~.form-check-label, .was-validated .form-check-input:valid~.form-check-label{
color: #fff;
}

.form-content input[type=number], .form-content input[type=password], .form-content input[type=text], .form-content select {
width: 100%;
padding: 9px 20px;
text-align: left;
border: 0;
outline: 0;
border-radius: 6px;
background-color: #fff;
font-size: 15px;
font-weight: 300;
color: #8D8D8D;
-webkit-transition: all 0.3s ease;
transition: all 0.3s ease;
margin-top: 16px;
}


.btn-primary{
background-color: #6C757D;
outline: none;
border: 0px;
 box-shadow: none;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active{
background-color: #495056;
outline: none !important;
border: none !important;
 box-shadow: none;
}

.form-content textarea {
position: static !important;
width: 100%;
padding: 8px 20px;
border-radius: 6px;
text-align: left;
background-color: #fff;
border: 0;
font-size: 15px;
font-weight: 300;
color: #8D8D8D;
outline: none;
resize: none;
height: 120px;
-webkit-transition: none;
transition: none;
margin-bottom: 14px;
}

.form-content textarea:hover, .form-content textarea:focus {
border: 0;
background-color: #ebeff8;
color: #8D8D8D;
}

.mv-up{
margin-top: -9px !important;
margin-bottom: 8px !important;
}

.invalid-feedback{
color: #ff606e;
}

.valid-feedback{
color: #2acc80;
}
