#main {
  margin-top: 72px;
}

#main h1 {
  margin: 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: #4e4039;
}

#main #intro {
  background: #fef8f5;
}

#intro h2 {
  color: #a08f86;
  margin: 15px 0 0 0;
  font-size: 24px;
}

#intro .btn {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 28px;
  border-radius: 3px;
  transition: 0.5s;
  margin-top: 30px;
  color: #fff;
  background: #eb5d1e;
}

#intro .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

#intro .img-fluid {
  max-width: 100%;
  height: auto;
}




.back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 99999;
    background: #eb5d1e;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
  }
  
  .back-to-top i {
    font-size: 24px;
    color: #fff;
    line-height: 0;
  }
  
  .back-to-top:hover {
    background: #ee7843;
    color: #fff;
  }
  
  .back-to-top.active {
    visibility: visible;
    opacity: 1;
  }

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
    padding: 60px 0;
    overflow: hidden;
  }
  
  .section-bg {
    background-color: #fef8f5;
  }
  
  .section-title {
    text-align: center;
    padding-bottom: 30px;
  }
  
  .section-title h2 {
    font-size: 24px;
    font-weight: 700;
    padding-bottom: 0;
    line-height: 1px;
    margin-bottom: 15px;
    color: #c2b7b1;
  }
  
  .section-title p {
    padding-bottom: 15px;
    margin-bottom: 15px;
    position: relative;
    font-size: 32px;
    font-weight: 700;
    color: #4e4039;
  }
  
  .section-title p::after {
    content: "";
    position: absolute;
    display: block;
    width: 60px;
    height: 2px;
    background: #eb5d1e;
    bottom: 0;
    left: calc(50% - 30px);
  }
  
/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
padding: 15px 0;
background-color: #fef5f1;
min-height: 40px;
}

.breadcrumbs h2 {
font-size: 24px;
font-weight: 300;
}

.breadcrumbs ol {
display: flex;
flex-wrap: wrap;
list-style: none;
padding: 0;
margin: 0;
font-size: 14px;
}

.breadcrumbs ol li+li {
padding-left: 10px;
}

.breadcrumbs ol li+li::before {
display: inline-block;
padding-right: 10px;
color: #6c757d;
content: "/";
}

@media (max-width: 768px) {
.breadcrumbs .d-flex {
    display: block !important;
}

.breadcrumbs ol {
    display: block;
}

.breadcrumbs ol li {
    display: inline-block;
}
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about {
  padding: 90px 0;
}

.about h3 {
    font-weight: 700;
    font-size: 34px;
    color: #4e4039;
  }
  
  .about h4 {
    font-size: 20px;
    font-weight: 700;
    margin-top: 5px;
    color: #7a6960;
  }
  
  .about i {
    font-size: 48px;
    margin-top: 15px;
    color: #f39e7a;
  }
  
  .about p {
    font-size: 15px;
    color: #5a6570;
  }
  
  @media (max-width: 991px) {
    .about .about-img img {
      max-width: 70%;
    }
  }
  
  @media (max-width: 767px) {
    .about .about-img img {
      max-width: 90%;
    }
  }
  
  /*--------------------------------------------------------------
  # Services
  --------------------------------------------------------------*/
  .services .icon-box {
    padding: 30px;
    position: relative;
    overflow: hidden;
    margin: 0 0 40px 0;
    background: #fff;
    box-shadow: 0 10px 29px 0 rgba(68, 88, 144, 0.1);
    transition: all 0.3s ease-in-out;
    border-radius: 15px;
    text-align: center;
    border-bottom: 3px solid #fff;
  }
  
  .services .icon-box:hover {
    transform: translateY(-5px);
    border-color: #ef7f4d;
  }
  
  .services .icon i {
    font-size: 48px;
    line-height: 1;
    margin-bottom: 15px;
    color: #ef7f4d;
  }
  
  .services .title {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
  }
  
  .services .title a {
    color: #111;
  }
  
  .services .description {
    font-size: 15px;
    line-height: 28px;
    margin-bottom: 0;
  }

  /*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio .portfolio-item {
    margin-bottom: 30px;
  }
  
  .portfolio #portfolio-flters {
    padding: 0;
    margin: 0 0 35px 0;
    list-style: none;
    text-align: center;
  }
  
  .portfolio #portfolio-flters li {
    cursor: pointer;
    margin: 0 15px 15px 0;
    display: inline-block;
    padding: 5px;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: #212529;
    margin-bottom: 5px;
    transition: all 0.3s ease-in-out;
  }
  
  .portfolio #portfolio-flters li:hover,
  .portfolio #portfolio-flters li.filter-active {
    color: #eb5d1e;
  }
  
  .portfolio #portfolio-flters li:last-child {
    margin-right: 0;
  }
  
  .portfolio .portfolio-wrap {
    transition: 0.3s;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    z-index: 1;
  }
  
  .portfolio .portfolio-wrap::before {
    content: "";
    background: rgba(122, 105, 96, 0.6);
    position: absolute;
    left: 100%;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all ease-in-out 0.3s;
    z-index: 2;
  }
  
  .portfolio .portfolio-wrap img {
    transition: 0.3s;
    position: relative;
    z-index: 1;
  }
  
  .portfolio .portfolio-wrap .portfolio-links {
    opacity: 0;
    left: 0;
    right: 0;
    top: calc(50% - 32px);
    text-align: center;
    z-index: 3;
    position: absolute;
    transition: all ease-in-out 0.3s;
  }
  
  .portfolio .portfolio-wrap .portfolio-links a {
    color: #eb5d1e;
    margin: 0 4px;
    line-height: 0;
    background-color: #fff;
    padding-top: 6px;
    padding-right: 1px;
    border-radius: 50px;
    text-align: center;
    width: 32px;
    height: 32px;
    display: inline-block;
    transition: 0.3s;
  }
  
  .portfolio .portfolio-wrap .portfolio-links a i {
    line-height: 0;
    font-size: 20px;
  }
  
  .portfolio .portfolio-wrap .portfolio-links a:hover {
    background: #eb5d1e;
    color: #fff;
  }
  
  .portfolio .portfolio-wrap .portfolio-info {
    opacity: 0;
    position: absolute;
    bottom: -20px;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 3;
    transition: all ease-in-out 0.3s;
  }
  
  .portfolio .portfolio-wrap .portfolio-info h4 {
    font-size: 20px;
    color: #fff;
    font-weight: 600;
  }
  
  .portfolio .portfolio-wrap .portfolio-info p {
    color: #ffffff;
    font-size: 14px;
    text-transform: uppercase;
  }
  
  .portfolio .portfolio-wrap:hover::before {
    left: 0;
  }
  
  .portfolio .portfolio-wrap:hover .portfolio-links {
    opacity: 1;
    top: calc(50% - 16px);
  }
  
  .portfolio .portfolio-wrap:hover .portfolio-info {
    opacity: 1;
    bottom: 0;
  }
  
  /*--------------------------------------------------------------
  # Portfolio Details
  --------------------------------------------------------------*/
  .portfolio-details {
    padding-top: 40px;
  }
  
  .portfolio-details .portfolio-details-slider img {
    width: 100%;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #eb5d1e;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #eb5d1e;
  }
  
  .portfolio-details .portfolio-info {
    padding: 30px;
    box-shadow: 0px 0 30px rgba(122, 105, 96, 0.08);
  }
  
  .portfolio-details .portfolio-info h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
  }
  
  .portfolio-details .portfolio-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
  }
  
  .portfolio-details .portfolio-info ul li+li {
    margin-top: 10px;
  }
  
  .portfolio-details .portfolio-description {
    padding-top: 30px;
  }
  
  .portfolio-details .portfolio-description h2 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .portfolio-details .portfolio-description p {
    padding: 0;
  }
  
  /*--------------------------------------------------------------
  # F.A.Q
  --------------------------------------------------------------*/
  .faq {
    padding: 60px 0;
  }
  
  .faq .faq-list {
    padding: 0;
    list-style: none;
  }
  
  .faq .faq-list li {
    border-bottom: 1px solid #eae7e5;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  
  .faq .faq-list .question {
    display: block;
    position: relative;
    font-family: #eb5d1e;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    font-weight: 600;
    padding-left: 25px;
    cursor: pointer;
    color: #c54811;
    transition: 0.3s;
  }
  
  .faq .faq-list i {
    font-size: 16px;
    position: absolute;
    left: 0;
    top: -2px;
  }
  
  .faq .faq-list p {
    margin-bottom: 0;
    padding: 10px 0 0 25px;
  }
  
  .faq .faq-list .icon-show {
    display: none;
  }
  
  .faq .faq-list .collapsed {
    color: #343a40;
  }
  
  .faq .faq-list .collapsed:hover {
    color: #eb5d1e;
  }
  
  .faq .faq-list .collapsed .icon-show {
    display: inline-block;
    transition: 0.6s;
  }
  
  .faq .faq-list .collapsed .icon-close {
    display: none;
    transition: 0.6s;
  }
  
  /*--------------------------------------------------------------
  # Team
  --------------------------------------------------------------*/
  .team {
    background: #fef8f5;
    padding: 90px 0;
  }
  
  .team .member {
    text-align: center;
    margin-bottom: 20px;
    background: #343a40;
    position: relative;
    overflow: hidden;
  }
  
  .team .member .member-info {
    opacity: 0;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    transition: 0.2s;
  }
  
  .team .member .member-info-content {
    position: absolute;
    left: 50px;
    right: 0;
    bottom: 0;
    transition: bottom 0.4s;
  }
  
  .team .member .member-info-content h4 {
    font-weight: 700;
    margin-bottom: 2px;
    font-size: 18px;
    color: #fff;
  }
  
  .team .member .member-info-content span {
    font-style: italic;
    display: block;
    font-size: 13px;
    color: #fff;
  }
  
  .team .member .social {
    position: absolute;
    left: -50px;
    top: 0;
    bottom: 0;
    width: 50px;
    transition: left ease-in-out 0.3s;
    background: rgba(78, 64, 57, 0.6);
    text-align: center;
  }
  
  .team .member .social a {
    transition: color 0.3s;
    display: block;
    color: #fff;
    margin-top: 15px;
  }
  
  .team .member .social a:hover {
    color: #eb5d1e;
  }
  
  .team .member .social i {
    font-size: 18px;
    margin: 0 2px;
  }
  
  .team .member:hover .member-info {
    background: linear-gradient(0deg, rgba(78, 64, 57, 0.95) 0%, rgba(78, 64, 57, 0.95) 15%, rgba(255, 255, 255, 0) 100%);
    opacity: 1;
    transition: 0.4s;
  }
  
  .team .member:hover .member-info-content {
    bottom: 30px;
    transition: bottom 0.4s;
  }
  
  .team .member:hover .social {
    left: 0;
    transition: left ease-in-out 0.3s;
  }
  
  /*--------------------------------------------------------------
  # Clients
  --------------------------------------------------------------*/
  .clients .clients-slider .swiper-slide img {
    opacity: 0.5;
    transition: 0.3s;
  }
  
  .clients .clients-slider .swiper-slide img:hover {
    opacity: 1;
  }
  
  .clients .clients-slider .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  
  .clients .clients-slider .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #eb5d1e;
  }
  
  .clients .clients-slider .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #eb5d1e;
  }
  
  /*--------------------------------------------------------------
  # Contact Us
  --------------------------------------------------------------*/
  .contact {
    padding: 90px 0;
  }
  .contact .info {
    border-top: 3px solid #eb5d1e;
    border-bottom: 3px solid #eb5d1e;
    padding: 30px;
    background: #fff;
    width: 100%;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
  }
  
  .contact .info i {
    font-size: 20px;
    color: #eb5d1e;
    float: left;
    width: 44px;
    height: 44px;
    background: #fdf1ec;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
  }
  
  .contact .info h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #7a6960;
  }
  
  .contact .info p {
    padding: 0 0 10px 60px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #ab9d95;
  }
  
  .contact .info .email p {
    padding-top: 5px;
  }

  .contact .info .phone p {
    padding: 0 0 10px 60px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #ab9d95;
  }
  
  .contact .info .social-links {
    padding-left: 60px;
  }
  
  .contact .info .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #333;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
    margin-right: 10px;
  }
  
  .contact .info .social-links a:hover {
    background: #eb5d1e;
    color: #fff;
  }
  
  .contact .info .email:hover i,
  .contact .info .address:hover i,
  .contact .info .phone:hover i {
    background: #eb5d1e;
    color: #fff;
  }
  
  .contact .php-email-form {
    width: 100%;
    border-top: 3px solid #eb5d1e;
    border-bottom: 3px solid #eb5d1e;
    padding: 30px;
    background: #fff;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
  }
  
  .contact .php-email-form .form-group {
    padding-bottom: 8px;
  }
  
  .contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .error-message br+br {
    margin-top: 25px;
  }
  
  .contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
  }
  
  .contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
  }
  
  .contact .php-email-form input,
  .contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
  }
  
  .contact .php-email-form input {
    height: 44px;
  }
  
  .contact .php-email-form textarea {
    padding: 10px 12px;
  }
  
  .contact .php-email-form button[type=submit] {
    background: #eb5d1e;
    border: 0;
    padding: 10px 24px;
    color: #fff;
    transition: 0.4s;
    border-radius: 4px;
  }
  
  .contact .php-email-form button[type=submit]:hover {
    background: #ef7f4d;
  }
  
  @-webkit-keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  /*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
  #footer {
    background: #fff;
    padding: 0 0 30px 0;
    color: #212529;
    font-size: 14px;
    background: #fef8f5;
  }
  
  #footer .footer-newsletter {
    padding: 50px 0;
    background: #fef8f5;
    text-align: center;
    font-size: 15px;
  }
  
  #footer .footer-newsletter h4 {
    font-size: 24px;
    margin: 0 0 20px 0;
    padding: 0;
    line-height: 1;
    font-weight: 600;
    color: #4e4039;
  }
  
  #footer .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 4px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    text-align: left;
  }
  
  #footer .footer-newsletter form input[type=email] {
    border: 0;
    padding: 4px 4px;
    width: calc(100% - 100px);
  }
  
  #footer .footer-newsletter form input[type=submit] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px;
    background: #eb5d1e;
    color: #fff;
    transition: 0.3s;
    border-radius: 4px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
  
  #footer .footer-newsletter form input[type=submit]:hover {
    background: #c54811;
  }
  
  #footer .footer-top {
    padding: 60px 0 30px 0;
    background: #fff;
  }
  
  #footer .footer-top .footer-contact {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-contact h4 {
    font-size: 22px;
    margin: 0 0 30px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 700;
  }
  
  #footer .footer-top .footer-contact p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Raleway", sans-serif;
    color: #5c5c5c;
  }
  
  #footer .footer-top h4 {
    font-size: 16px;
    font-weight: bold;
    color: #212529;
    position: relative;
    padding-bottom: 12px;
  }
  
  #footer .footer-top .footer-links {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  #footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #f39e7a;
    font-size: 18px;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  
  #footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
  }
  
  #footer .footer-top .footer-links ul a {
    color: #5c5c5c;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul a:hover {
    text-decoration: none;
    color: #eb5d1e;
  }
  
  #footer .footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #eb5d1e;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
  }
  
  #footer .footer-top .social-links a:hover {
    background: #ef7f4d;
    color: #fff;
    text-decoration: none;
  }
  
  #footer .copyright {
    text-align: center;
    float: left;
  }
  
  #footer .credits {
    float: right;
    text-align: center;
    font-size: 13px;
    color: #212529;
  }
  
  #footer .credits a {
    color: #eb5d1e;
  }
  
  @media (max-width: 575px) {
  
    #footer .copyright,
    #footer .credits {
      float: none;
      -moz-text-align-last: center;
      text-align-last: center;
      padding: 3px 0;
    }
  }